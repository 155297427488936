<template>
  <div>
    <div class="flex-row">
      <div class="item-text rich-text-content" v-html="aspect.text"></div>
      <div class="m-2">
        <b-button
          v-if="allows_crud"
          class="act-button"
          v-b-tooltip.v-secondary.noninteractive="'Agregar Acuerdo'"
          size="sm"
          @click="$bvModal.show(`modal-agreement-${act_id}-${aspect_id}`)"
        >
          <b-icon-plus-circle></b-icon-plus-circle>
        </b-button>
      </div>
    </div>
    <template v-for="agreement in agreements">
      <div class="mb-2 form-container flex-row page-break" :key="agreement.id">
        <div :class="allows_crud ? 'agreement-form' : ''" class="w-100">
          <div class="flex-row">
            <div class="w-15-head">Fecha de Cumplimiento</div>
            <div class="w-15">{{ agreement.compliance_date }}</div>
            <div class="w-15-head">Acuerdos</div>
            <div class="w-70 text-justify pl-2">{{ agreement.agreement }}</div>
          </div>
          <div class="flex-row">
            <div class="w-15-head">Cumplimiento</div>
            <div class="w-15">
              <template v-if="allows_crud">
                <b-form-select
                  v-model="agreement.is_fulfilled"
                  :options="FulfillTypes"
                  @change="patchAgreement(agreement)"
                  value-field="id"
                  text-field="value"
                ></b-form-select>
              </template>
              <template v-else>
                {{ fulfillment(agreement) }}
              </template>
            </div>
            <div class="w-15-head">Responsables</div>
            <div class="w-70 text-justify pl-2">
              {{ agreement.responsibles }}
            </div>
          </div>
          <div class="flex-row">
            <div class="w-15-head">Observaciones</div>
            <div class="w-100 text-justify border pl-2">
              {{ agreement.observations }}
            </div>
          </div>
        </div>
        <div v-if="allows_crud" class="mt-1 agreement-buttons">
          <b-button
            class="ml-1 mb-1"
            variant="info"
            size="sm"
            v-b-tooltip.v-secondary.noninteractive="'Editar Acuerdo'"
            @click="launchUpdateModal(agreement)"
          >
            <b-icon-pencil-square></b-icon-pencil-square>
          </b-button>
          <b-button
            class="act-button ml-1 mb-1"
            size="sm"
            v-b-tooltip.v-secondary.noninteractive="'Eliminar Acuerdo'"
            @click="launchDelete(agreement)"
          >
            <b-icon-x-circle></b-icon-x-circle>
          </b-button>
        </div></div
    ></template>
    <!-- Modals -->
    <b-modal
      :id="`modal-agreement-${act_id}-${aspect_id}`"
      :title="selected_agreement ? 'Editar Acuerdo' : 'Crear Acuerdo'"
      hide-footer
      @hide="hideModal"
      size="lg"
    >
      <AspectAgreementForm
        :Agreement="selected_agreement"
        :act_id="act_id"
        :aspect_id="aspect_id"
        :Aspects="Aspects"
        :FulfillTypes="FulfillTypes"
        :allows_crud="true"
        @created="slotCreateAgreement"
        @updated="slotUpdateAgreement"
      ></AspectAgreementForm>
    </b-modal>
  </div>
</template>

<script>
import { toast } from "@/utils/utils";

export default {
  name: "AspectAgreement",
  components: {
    AspectAgreementForm: () => import("./AspectAgreementForm"),
  },
  props: {
    Act: {
      type: Object,
      required: true,
    },
    act_id: {
      type: Number,
      required: true,
    },
    aspect_id: {
      type: Number,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
    Aspects: {
      type: Array,
      required: true,
    },
    FulfillTypes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      agreements: [],
      selected_agreement: null,
    };
  },
  computed: {
    aspect() {
      return this.Aspects.find((x) => x.id == this.aspect_id);
    },
  },
  methods: {
    fulfillment(agreement) {
      if (!agreement.is_fulfilled) return "";
      const type = this.FulfillTypes.find(
        (x) => x.id == agreement.is_fulfilled
      );
      return type.value;
    },
    fetchAgreements() {
      this.$restful
        .Get(`/cideu/agreement/?aspect=${this.aspect_id}`)
        .then((response) => {
          this.agreements = response;
        });
    },
    hideModal() {
      this.selected_agreement = null;
    },
    deleteAgreement(agreement) {
      this.$restful.Delete(`/cideu/agreement/${agreement.id}/`).then(() => {
        const index_aspect = this.Act.agreements.findIndex(
          (x) => x == agreement.id
        );
        if (index_aspect != -1) {
          this.Act.agreements.splice(index_aspect, 1);
        }
        const index_agreements = this.Act.agreements_fullfiled.findIndex(
          (x) => x == agreement.id
        );
        if (index_agreements != -1) {
          this.Act.agreements_fullfiled.splice(index_agreements, 1);
        }
        const index = this.agreements.findIndex((x) => x.id == agreement.id);
        if (index != -1) {
          this.agreements.splice(index, 1);
        }
        toast("Acuerdo eliminado.");
      });
    },
    slotCreateAgreement(agreement) {
      this.Act.agreements.push(agreement.id);
      this.agreements.push(agreement);
      this.$bvModal.hide(`modal-agreement-${this.act_id}-${this.aspect_id}`);
    },
    patchAgreement(agreement) {
      this.$restful
        .Patch(`/cideu/agreement/${agreement.id}/`, {
          is_fulfilled: agreement.is_fulfilled,
        })
        .then((response) => {
          this.slotUpdateAgreement(response);
          toast("Acuerdo actualizado.");
        });
    },
    slotUpdateAgreement(agreement) {
      this.$bvModal.hide(`modal-agreement-${this.act_id}-${this.aspect_id}`);
      if (this.Act.agreements_fullfiled.includes(agreement.id)) {
        if (agreement.is_fulfilled != 1) {
          const index = this.Act.agreements_fullfiled.findIndex(
            (x) => x == agreement.id
          );
          if (index != -1) this.Act.agreements_fullfiled.splice(index, 1);
        }
      } else {
        if (agreement.is_fulfilled == 1) {
          this.Act.agreements_fullfiled.push(agreement.id);
        }
      }
      const index = this.agreements.findIndex((x) => x.id == agreement.id);
      if (index != -1) {
        this.agreements.splice(index, 1, agreement);
      } else {
        this.agreements.push(agreement);
      }
    },
    launchUpdateModal(agreement) {
      this.selected_agreement = agreement;
      this.$bvModal.show(`modal-agreement-${this.act_id}-${this.aspect_id}`);
    },
    launchDelete(agreement) {
      this.$swal({
        title: "¿Está seguro de que desea eliminar el Acuerdo?",
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.deleteAgreement(agreement);
        }
      });
    },
  },
  created() {
    this.fetchAgreements();
  },
};
</script>

<style scoped src="@/utils/rich_text_editor.css">
</style>

<style scoped>
.flex-row {
  display: flex;
  flex-direction: row;
}
.item-text {
  text-align: justify;
  padding: 0.1em 1em;
  width: 95%;
}
.row-first {
  width: 20%;
  font-weight: bold;
  word-wrap: break-word;
}
.row-second {
  width: 80%;
  text-align: justify;
}
.w-15 {
  width: 15%;
  border: 1px solid var(--secondary-color);
}
.w-15-head {
  width: 15%;
  border: 1px solid var(--secondary-color);
  background-color: var(--primary-color);
  font-weight: bold;
  word-wrap: break-word;
}
.w-70 {
  width: 70%;
  border: 1px solid var(--secondary-color);
}
.agreement {
  border: 1px dashed var(--secondary-color);
}
.border {
  border: 2px solid var(--secondary-color);
}
.middle {
  vertical-align: middle;
  margin: 0px;
  padding: 0px;
}
.act-button {
  background-color: var(--primary-color);
  max-width: 15rem;
  color: var(--primary-font-color);
  align-self: center;
}
.form-container {
  border: 4px solid var(--secondary-color);
}
.agreement-buttons {
  width: fit-content;
}
.agreement-form {
  width: 95% !important;
}
.page-break {
  page-break-before: auto;
  page-break-after: auto;
  page-break-inside: avoid;
}
</style>